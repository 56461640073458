import React from "react";
// import GoogleOneTapLogin from "react-google-one-tap-login";
import GoogleLoginComponent from "./GoogleLoginComponent.js";

const LoginPage = () => (
  <div className="pageDiv">
    <br />
    <div className="transDiv">
      <h2 style={{ textAlign: "center" }}>Administrator Login</h2>
      <br />

      <GoogleLoginComponent />

      {/* TODO: Figure out how to do this shit
      
      <GoogleOneTapLogin
      onError={(error) => console.log(error)}
      onSuccess={(response) => console.log(response)}
      googleAccountConfigs={{
        client_id:
          "689304309541-p6n98chm1dkk57jsviojouc0j586dt8v.apps.googleusercontent.com",
      }}
    />

    <div
      id="g_id_onload"
      data-client_id="689304309541-p6n98chm1dkk57jsviojouc0j586dt8v.apps.googleusercontent.com"
      data-context="signin"
      data-ux_mode="popup"
      data-login_uri="http://localhost:3000/login"
      data-auto_select="true"
    ></div>

    <div
      className="g_id_signin"
      data-type="standard"
      data-shape="rectangular"
      data-theme="outline"
      data-text="signin_with"
      data-size="large"
      data-logo_alignment="left"
    ></div> */}
    </div>
  </div>
);

export default LoginPage;
