import React, { Component } from "react";
import { GoogleLogin, GoogleLogout } from "react-google-login";

const CLIENT_ID =
  "689304309541-p6n98chm1dkk57jsviojouc0j586dt8v.apps.googleusercontent.com";

class GoogleLoginComponent extends Component {
  constructor() {
    super();
    this.state = {
      isLoggedIn: false,
      userInfo: {
        name: "",
        emailId: "",
      },
      user_roles: []
    };
  }

  // Success Handler
  responseGoogleSuccess = (response) => {
    let userInfo = {
      name: response.profileObj.name,
      emailId: response.profileObj.email,
    };
    function getRoleMapping(){
      return fetch("https://www.hollywooddoors.net/accounts.json").then(
        r => r.json()
      ).catch(e => {
        console.log(e);
        return {"admin": ["anreynolds12@gmail.com", "christian.watts@communitycustomsoftware.com"]};
      });
    }
    getRoleMapping().then(role_mapping => {
      let user_roles = []
      for (const [role, emails] of Object.entries(role_mapping)) {
        if (emails.includes(response.profileObj.email)) {
          user_roles.push(role)
        }
      }
      this.setState({ isLoggedIn: true, userInfo: userInfo, user_roles: user_roles});

      if (typeof Storage !== "undefined") {
        if (!localStorage.getItem("isLoggedIn")) {
          localStorage.setItem("username", response.profileObj.name);
          localStorage.setItem("roles", user_roles);
          localStorage.setItem("isLoggedIn", true);
          window.location.reload(false);
        }
      } else {
        console.log("Error: Browser does not support web storage");
      }
    }
    )
  };

  // Error Handler
  responseGoogleError = (response) => {
    console.log(response);
  };

  // Logout Session and Update State
  logout = (response) => {
    let userInfo = {
      name: "",
      emailId: "",
    };
    this.setState({ userInfo, isLoggedIn: false });

    if (typeof Storage !== "undefined") {
      if (localStorage.getItem("isLoggedIn")) {
        localStorage.removeItem("username");
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("roles");
        window.location.reload(false);
      }
    } else {
      console.log("Error: Browser does not support web storage");
    }
  };

  render() {
    return (
      <div>
        <div>
          {this.state.isLoggedIn ? (
            <div style={{ textAlign: "center" }}>
              <h3>
                <i>Welcome, {this.state.userInfo.name}</i>
                <br /> <br />
                <p>Email: {this.state.userInfo.emailId}</p>
              </h3>

              <GoogleLogout
                clientId={CLIENT_ID}
                buttonText={"Logout"}
                onLogoutSuccess={this.logout}
                style={{ color: "black" }}
              ></GoogleLogout>
            </div>
          ) : (
            <div style={{ textAlign: "center" }}>
              <GoogleLogin
                clientId={CLIENT_ID}
                buttonText="Sign In with Google"
                onSuccess={this.responseGoogleSuccess}
                onFailure={this.responseGoogleError}
                isSignedIn={true}
                cookiePolicy={"single_host_origin"}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default GoogleLoginComponent;
