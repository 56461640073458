import React, { useState } from "react";
import { Button, Nav } from "react-bootstrap";
import { useAlert } from "react-alert";

function RequestModal(props) {
  const typeInit = props.TypeInit || "New Garage Door";
  let errMsg, emailErr, phoneErr;
  const [serviceType, setServiceType] = useState(typeInit),
    [repair, setRepair] = useState(""),
    [designUrl, setDesignUrl] = useState(""),
    [heard, setHeard] = useState(""),
    [fName, setFName] = useState(""),
    [lName, setLName] = useState(""),
    [phone, setPhone] = useState(""),
    [email, setEmail] = useState(""),
    [address1, setAddress1] = useState(""),
    [address2, setAddress2] = useState(""),
    [city, setCity] = useState(""),
    [addrState, setAddrState] = useState("TN"),
    [zipcode, setZipcode] = useState("");

  const [showRepair, setShowRepair] = useState(typeInit === "Repairs"),
    [showUrl, setShowUrl] = useState(typeInit === "New Garage Door");
  const closeModal = () => {
    props.closeModal();
  };
  const alert = useAlert();

  function handleSubmit(event) {
    event.preventDefault();

    checkValid().then((isValid) => {
      if (isValid) {
        sendEmail()
          .then((res) => {
            alert.success(
              <div style={{ textTransform: "none" }}>
                Request successfully submitted! <br />
                You'll be contacted as soon as possible.
              </div>,
              { timeout: 5000 }
            );
            closeModal();
          })
          .catch((err) => {
            alert.error(
              <div style={{ textTransform: "none" }}>
                Submission failed! <br /> Please try again or contact us
                directly at: <br />
                (423) 645-7147 or hollywoodgaragedoorsga@gmail.com
              </div>,
              { timeout: 15000 }
            );
            console.log(err);
          });
      } else {
        alert.error(
          <div style={{ textTransform: "none" }}>
            {errMsg ? <p>{errMsg}</p> : null}
            {emailErr ? <p>{emailErr}</p> : null}
            {phoneErr ? <p>{phoneErr}</p> : null}
          </div>,
          { timeout: 7000 }
        );
      }
    });
  }

  const sendEmail = async () => {
    const fullAddress = `${address1} ${address2}, ${city}, ${addrState} ${zipcode}`;
    const fullName = `${lName}, ${fName}`;

    const reqOps = {
      method: "POST",
      mode: 'cors',
      headers: { 
        "Content-Type": "application/json",
        "hwdcheck": "true"
      },
      body: JSON.stringify({
        Request_Type: serviceType,
        Repair_Request: repair || null,
        DoorLink_URL: designUrl || null,
        Heard_About_You: heard || null,
        Name: fullName,
        Phone: phone,
        Email: email,
        Address: fullAddress || null,
      }),
    };

    const res = await fetch("https://2uvrqazfewfhkchfqaid46zfma0cyqhj.lambda-url.us-east-1.on.aws/", reqOps);
    const body = await res.json();

    if (res.status !== 200) {
      throw Error(body.message);
    } else {
      return body;
    }
  };

  const checkValid = async () => {
    errMsg = null;
    emailErr = null;
    phoneErr = null;
    let valid = true;

    if (fName === "" || lName === "" || phone === "" || email === "") {
      errMsg = "Please fill in all required fields.";
      valid = false;
    }

    if (!!email && !/^(.+[@].+[.].*)/.test(email)) {
      emailErr = "Invalid Email address.";
      valid = false;
    }

    if (
      !!phone &&
      !/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(
        phone
      )
    ) {
      phoneErr = "Invalid Phone number.";
      valid = false;
    }

    return valid;
  };

  const handleTypeChange = (e) => {
    const val = e.target.value;
    setServiceType(val);

    if (val === "New Garage Door") {
      setShowUrl(true);
      setShowRepair(false);
    } else if (val === "Repairs") {
      setShowUrl(false);
      setShowRepair(true);
    } else {
      setShowUrl(false);
      setShowRepair(false);
    }
  };

  return (
    <>
      <h2 className="modalHeader">What can we do for you?</h2>
      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <br />
        <label>
          What kind of service do you need?
          <select value={serviceType} onChange={handleTypeChange}>
            <option value="New Garage Door">New Garage Door</option>
            <option value="New Operator">New Operator</option>
            <option value="Repairs">Repairs and Replacements</option>
          </select>
        </label>
        <br />
        <br />

        {showRepair ? (
          <label>
            Specify what repair is needed:
            <br />
            <textarea
              value={repair}
              onChange={(e) => setRepair(e.target.value)}
              placeholder="Broken spring, bent door track, etc..."
            />
            <br />
          </label>
        ) : null}

        {showUrl ? (
          <div>
            <label>
              Input the URL of your DoorLink Design Results:
              <br />
              <input
                type="text"
                value={designUrl}
                onChange={(e) => setDesignUrl(e.target.value)}
                placeholder="https://doorlinkmfg.com/design-shop/?result=#####"
              />
              <br />
            </label>
            <p style={{ margin: "5px 20px 10px 50px" }}>
              <i>Don't have one yet? </i>
              <Nav.Link
                href="https://doorlinkmfg.com/design-shop/"
                target="_blank"
                className="myBtn linkBtn"
                style={{ margin: "10px 0 10px 20px", fontSize: "16px" }}
              >
                Design Your Own!
              </Nav.Link>
            </p>
          </div>
        ) : null}

        <label>
          How did you hear about us?
          <br />
          <textarea
            value={heard}
            onChange={(e) => setHeard(e.target.value)}
            placeholder="Google, radio, my neighbor..."
          />
        </label>
        <br />
        <label>
          * First Name: <br />
          <input
            type="text"
            value={fName}
            onChange={(e) => setFName(e.target.value)}
            placeholder="John"
          />
        </label>
        <br />
        <label>
          * Last Name: <br />
          <input
            type="text"
            value={lName}
            onChange={(e) => setLName(e.target.value)}
            placeholder="Smith"
          />
        </label>
        <br />
        <label>
          * Phone: <br />
          <input
            type="text"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="(555) 555-1234"
          />
        </label>
        <br />
        <label>
          * Email: <br />
          <input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="johnsmith@gmail.com"
          />
        </label>
        <br />
        <label>
          Street Address: <br />
          <input
            type="text"
            value={address1}
            onChange={(e) => setAddress1(e.target.value)}
            placeholder="123 Main St"
          />
          <br />
          <input
            type="text"
            value={address2}
            onChange={(e) => setAddress2(e.target.value)}
            placeholder="Apt 123"
          />
        </label>
        <br />
        <label>
          City:
          <br />
          <input
            type="text"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            placeholder="Chattanooga"
          />
        </label>
        <label>
          State: <br />
          <select
            value={addrState}
            onChange={(e) => setAddrState(e.target.value)}
          >
            <option value="TN">Tennessee</option>
            <option value="GA">Georgia</option>
            <option value="AL">Alabama</option>
          </select>
        </label>
        <label>
          Zip: <br />
          <input
            type="text"
            value={zipcode}
            onChange={(e) => setZipcode(e.target.value)}
            placeholder="12345"
          />
        </label>
        <br />
        <br />
        <div>
          <input
            className="btn myBtn"
            type="submit"
            value="Submit"
            style={{ margin: "25px" }}
          />
          <Button className="myBtn" onClick={closeModal}>
            Cancel
          </Button>
        </div>
      </form>
    </>
  );
}

export default RequestModal;
