import React from "react";
import { Card } from "react-bootstrap";
import logo from "../../assets/mainLogo.png";
import RequestBtn from "../common/RequestBtn.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const ContactPage = () => (
  <div className="pageDiv">
    <img src={logo} alt="Holly Wood Doors logo" style={{ maxWidth: "100%" }} />
    <div className="servicesDiv">
      <Card className="servicesCards">
        <Card.Body>
          <Card.Title className="cardTitle">Contact Us</Card.Title>
          <Card.Text className="mainBody">
            You can either fill out our online request form with the button
            below, or you can call or email us directly. Our technicians will
            get back to you as soon as possible, typically by the next business
            day. Thank you for considering Holly Wood Doors!
            <br />
            <br /> &emsp;
            <a href="tel:+14236457147" style={{ textDecoration: "none" }}>
              <FontAwesomeIcon icon={faPhone} /> (423) 645-7147
            </a>{" "}
            <br />
            &emsp;
            <a
              href="mailto:hollywoodgaragedoorsga@gmail.com"
              style={{ textDecoration: "none" }}
            >
              <FontAwesomeIcon icon={faEnvelope} />{" "}
              hollywoodgaragedoorsga@gmail.com
            </a>{" "}
            <br />
            &emsp;
            <a
              href="https://www.facebook.com/hollywooddoors"
              style={{ textDecoration: "none" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faFacebookF} />
              &ensp;Holly Wood Doors
            </a>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "40px",
              }}
            >
              <RequestBtn>Request Service</RequestBtn>
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  </div>
);

export default ContactPage;
