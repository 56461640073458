import React, { useState } from "react";
import * as QuoteFunctions from "./QuoteFunctions.js";
import { colors, additions } from "./QuoteConstants.js";
import { Collapse } from "react-collapse";
import ReactSlider from "react-slider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import Popup from "reactjs-popup";
import Select from 'react-select';
import "reactjs-popup/dist/index.css";

function QuotePage() {
  //hooks to open and close the various trees
  const [seriesIsOpen, setSeriesIsOpen] = useState(true),
    [selectedOption, setSelectedOption] = useState(null),
    [seriesIcon, setSeriesIcon] = useState(faAngleDown),
    [modelIsOpen, setModelIsOpen] = useState(true),
    [modelIcon, setModelIcon] = useState(faAngleDown),
    [sizeIsOpen, setSizeIsOpen] = useState(true),
    [sizeIcon, setSizeIcon] = useState(faAngleDown),
    [showModSz, setShowModSz] = useState(false),
    [showInserts, setShowInserts] = useState(false);

  //hooks for the quote attributes
  const [series, setSeries] = useState(""),
    [model, setModel] = useState(""),
    [size, setSize] = useState(""),
    [insulation, setInsulation] = useState(null),
    [windows, setWindows] = useState(null),
    [inserts, setInserts] = useState(null),
    [color, setColor] = useState(""),
    [quantity, setQuantity] = useState(1),
    [markup, setMarkup] = useState(0);

  //hooks for the totals
  const [fullTotal, setFullTotal] = useState(0),
    [materialTotal, setMaterialTotal] = useState(0),
    [businessTotal, setBusinessTotal] = useState(0);

  // hooks for itemized content
  const [materialComp, setMaterialComp] = useState([]),
    [businessComp, setBusinessComp] = useState([]);

  function changeCollapse(element) {
    if (element === "series") {
      setSeriesIsOpen(!seriesIsOpen);
      setSeriesIcon(seriesIsOpen ? faAngleRight : faAngleDown);
    }
    if (element === "model") {
      setModelIsOpen(!modelIsOpen);
      setModelIcon(modelIsOpen ? faAngleRight : faAngleDown);
    }
    if (element === "size") {
      setSizeIsOpen(!sizeIsOpen);
      setSizeIcon(sizeIsOpen ? faAngleRight : faAngleDown);
    }
  }

  function calculateTotal() {
    if (!series || !model || !size || !color) {
      return null;
    }

    let props = {
      series,
      model,
      size,
      hasInsulation: insulation || false,
      hasWindows: windows || false,
      hasInserts: inserts || false,
      color,
      quantity,
      discount: markup + 100,
      additionalItems: selectedOption,
    };

    let itemized = QuoteFunctions.itemizeDoorQuote(props),
      tempTotal = QuoteFunctions.sumItems(itemized),
      tempMat = QuoteFunctions.sumItems(itemized, "manufacturer"),
      tempBus = QuoteFunctions.sumItems(itemized, "business"),
      tempMatComp = [],
      tempBusComp = [];

    //Round up to the nearest 0.00
    tempTotal = Math.round(tempTotal * 100) / 100;
    tempMat = Math.round(tempMat * 100) / 100;
    tempBus = Math.round(tempBus * 100) / 100;

    setMaterialTotal(tempMat);
    setBusinessTotal(tempBus);
    setFullTotal(tempTotal);

    itemized.forEach((item) => {
      if (item.type === "manufacturer")
        tempMatComp.push(`${item.itemName} cost: $${item.cost}`);
      if (item.type === "business")
        tempBusComp.push(`${item.itemName} cost: $${item.cost}`);
    });

    setBusinessComp(tempBusComp);
    setMaterialComp(tempMatComp);
  }

  function BusinessInfo() {
    if (businessComp.length === 0) {
      return (
        <div>
          <br />
          <i>No details to show</i>
        </div>
      );
    } else {
      return (
        <>
          <ul>
            {businessComp.map(function (b, index) {
              return (
                <li
                  key={`businessBullet ${index}`}
                  style={{ textTransform: "capitalize" }}
                >
                  {b}
                </li>
              );
            })}
          </ul>
        </>
      );
    }
  }

  function MaterialInfo() {
    if (materialComp.length === 0) {
      return (
        <div>
          <br />
          <i>No details to show</i>
        </div>
      );
    } else {
      return (
        <>
          <ul>
            {materialComp.map(function (b, index) {
              return (
                <li
                  key={`materialBullet ${index}`}
                  style={{ textTransform: "capitalize" }}
                >
                  {b}
                </li>
              );
            })}
          </ul>
        </>
      );
    }
  }

  function SeriesRadio() {
    let array = QuoteFunctions.getSeries();

    return array.map(function (s) {
      let idName = `s${s}_radio`;
      return (
        <span key={idName}>
          <input
            type="radio"
            value={s}
            name="series_radio"
            id={idName}
            className="allRadio"
            checked={series === s}
            onChange={() => {
              setSeries(s);
              setShowModSz(true);
              setModel("");
              setSize("");
              document.getElementsByName("model_radio").checked = false;
              document.getElementsByName("size_radio").checked = false;
            }}
            style={{ display: "none" }}
          />
          <label htmlFor={idName} className="quote-radio-label">
            {s}
          </label>
        </span>
      );
    });
  }

  function ModelRadio() {
    if (!series) {
      return (
        <div>
          <p>
            <i>Select a Series</i>
          </p>
        </div>
      );
    } else {
      let array = QuoteFunctions.getModels(series);

      return array.map(function (m) {
        let idName = `m${m}_radio`;
        return (
          <span key={idName}>
            <input
              type="radio"
              value={m}
              name="model_radio"
              id={idName}
              className="allRadio"
              checked={model === m}
              onChange={() => {
                setModel(m);
              }}
              style={{ display: "none" }}
            />
            <label htmlFor={idName} className="quote-radio-label">
              {m}
            </label>
          </span>
        );
      });
    }
  }

  function SizeRadio() {
    if (!series) {
      return (
        <div>
          <p>
            <i>Select a Series</i>
          </p>
        </div>
      );
    } else {
      let array = QuoteFunctions.getSizes(series);

      return array.map(function (sz) {
        let idName = `sz${sz}_radio`;
        return (
          <span key={idName}>
            <input
              type="radio"
              value={sz}
              name="size_radio"
              id={idName}
              className="allRadio"
              checked={size === sz}
              onChange={() => {
                setSize(sz);
              }}
              style={{ display: "none" }}
            />
            <label htmlFor={idName} className="quote-radio-label">
              {sz}
            </label>
          </span>
        );
      });
    }
  }

  function ColorRadio() {
    return colors.map(function (c) {
      let idName = `${c}_radio`,
        bgColor = "#efefef",
        txtColor = "black";

      switch (c) {
        case "brown":
          bgColor = "saddlebrown";
          txtColor = "white";
          break;
        case "gray":
          bgColor = "darkgray";
          break;
        case "almond":
          bgColor = "blanchedalmond";
          break;
        case "sandstone":
          bgColor = "sandybrown";
          break;
        case "desert tan":
          bgColor = "peachpuff";
          break;
        case "walnut":
          bgColor = "brown";
          txtColor = "white";
          break;
        case "oak":
          bgColor = "chocolate";
          txtColor = "white";
          break;
        case "driftwood":
          bgColor = "dimgrey";
          txtColor = "white";
          break;
        case "black":
          bgColor = "#292929";
          txtColor = "white";
          break;
        default:
          bgColor = c;
      }

      return (
        <span key={idName}>
          <input
            type="radio"
            value={c}
            name="color_radio"
            id={idName}
            className="allRadio"
            checked={color === c}
            onChange={() => {
              setColor(c);
            }}
            style={{ display: "none" }}
          />
          <label
            htmlFor={idName}
            className="color-radio-label"
            style={{ backgroundColor: bgColor, color: txtColor }}
          >
            {c}
          </label>
        </span>
      );
    });
  }

  return (
    <>
      <div className="pageDiv" style={{ paddingTop: "2%" }}>
        {localStorage.roles.includes("admin") ? (
          <div className="transDiv">
            <h2>Generate a New Quote</h2>
            <br />
            <div id="seriesDiv" className="radioDiv">
              <label>
                <span
                  onClick={() => {
                    changeCollapse("series");
                  }}
                >
                  <FontAwesomeIcon icon={seriesIcon} />
                </span>
                &ensp;Series
              </label>
              <Collapse isOpened={seriesIsOpen}>
                <div className="radio-wrap">
                  <SeriesRadio />
                </div>
                {showModSz ? (
                  <div id="modelDiv" className="innerRadioDiv">
                    <label>
                      <span
                        onClick={() => {
                          changeCollapse("model");
                        }}
                      >
                        <FontAwesomeIcon icon={modelIcon} />
                      </span>
                      &ensp;Model
                    </label>
                    <Collapse isOpened={modelIsOpen}>
                      <div className="radio-wrap">
                        <ModelRadio />
                      </div>
                    </Collapse>
                  </div>
                ) : null}

                {showModSz ? (
                  <div id="sizeDiv" className="innerRadioDiv">
                    <label>
                      <span
                        onClick={() => {
                          changeCollapse("size");
                        }}
                      >
                        <FontAwesomeIcon icon={sizeIcon} />
                      </span>
                      &ensp;Size
                    </label>
                    <Collapse isOpened={sizeIsOpen}>
                      <div className="radio-wrap">
                        <SizeRadio />
                      </div>
                    </Collapse>
                  </div>
                ) : null}
              </Collapse>
            </div>

            <div id="windowsDiv" className="radioDiv">
              <label>&ensp;Windows?</label>
              <br />
              <div className="radio-wrap">
                <input
                  type="radio"
                  value="Yes"
                  name="windows_radio"
                  id="windows_yes"
                  className="allRadio"
                  checked={windows === true}
                  onChange={() => {
                    setWindows(true);
                    setShowInserts(true);
                  }}
                  style={{ display: "none" }}
                />
                <label htmlFor="windows_yes" className="quote-radio-label">
                  Yes
                </label>
                <input
                  type="radio"
                  value="No"
                  name="windows_radio"
                  id="windows_no"
                  className="allRadio"
                  checked={windows === false}
                  onChange={() => {
                    setWindows(false);
                    setShowInserts(false);
                    setInserts("");
                    document.getElementsByName("inserts_radio").checked = false;
                  }}
                  style={{ display: "none" }}
                />
                <label htmlFor="windows_no" className="quote-radio-label">
                  No
                </label>
              </div>
              {showInserts ? (
                <div id="insertsDiv" className="innerRadioDiv">
                  <label>&ensp;Inserts?</label>
                  <br />
                  <div className="radio-wrap">
                    <input
                      type="radio"
                      value="Yes"
                      name="inserts_radio"
                      id="inserts_yes"
                      className="allRadio"
                      checked={inserts === true}
                      onChange={() => {
                        setInserts(true);
                      }}
                      style={{ display: "none" }}
                    />
                    <label htmlFor="inserts_yes" className="quote-radio-label">
                      Yes
                    </label>
                    <input
                      type="radio"
                      value="No"
                      name="inserts_radio"
                      id="inserts_no"
                      className="allRadio"
                      checked={inserts === false}
                      onChange={() => {
                        setInserts(false);
                      }}
                      style={{ display: "none" }}
                    />
                    <label htmlFor="inserts_no" className="quote-radio-label">
                      No
                    </label>
                  </div>
                </div>
              ) : null}
            </div>

            <div id="insulationDiv" className="radioDiv">
              <label>&ensp;Insulation?</label>
              <br />
              <div className="radio-wrap">
                <input
                  type="radio"
                  value="Yes"
                  name="insul_radio"
                  id="insul_yes"
                  className="allRadio"
                  checked={insulation === true}
                  onChange={() => {
                    setInsulation(true);
                  }}
                  style={{ display: "none" }}
                />
                <label htmlFor="insul_yes" className="quote-radio-label">
                  Yes
                </label>
                <input
                  type="radio"
                  value="No"
                  name="insul_radio"
                  id="insul_no"
                  className="allRadio"
                  checked={insulation === false}
                  onChange={() => {
                    setInsulation(false);
                  }}
                  style={{ display: "none" }}
                />
                <label htmlFor="insul_no" className="quote-radio-label">
                  No
                </label>
              </div>
            </div>

            <div id="colorDiv" className="radioDiv">
              <label>&ensp;Color</label>
              <br />
              <div className="radio-wrap">
                <ColorRadio />
              </div>
            </div>

            <div //quantity div
              id="quantityDiv"
              className="radioDiv"
              style={{ paddingRight: "30px" }}
            >
              <label>&ensp;Number of Doors</label>
              <div className="radio-wrap">
                <input
                  type="number"
                  name="quantity"
                  className="quantityField"
                  value={quantity}
                  onChange={(e) => {
                    setQuantity(e.target.value);
                  }}
                />
              </div>
            </div>
            <div id="markupDiv" className="radioDiv">
              <label>&ensp;Markup Percentage</label>
              <br />
              <ReactSlider
                className="slider"
                thumbClassName="slider-thumb"
                trackClassName="slider-track"
                markClassName="slider-mark"
                marks={5}
                step={5}
                min={-40}
                max={40}
                value={markup}
                onChange={(val) => setMarkup(val)}
                renderThumb={(props, state) => (
                  <div {...props}>
                    <br />
                    {markup < 0 ? (
                      <label className="markup-label">
                        {Math.abs(markup)}% Discount
                      </label>
                    ) : (
                      <label className="markup-label">{markup}% Markup</label>
                    )}
                  </div>
                )}
              />
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>

            <div id="additionsDiv" className="radioDiv">
            <label>&ensp;Additions?</label>
              <br />
              <div className="radio-wrap" style={{paddingRight:"30px"}}>
            <Select
              defaultValue={null}
              value={selectedOption}
              onChange={setSelectedOption}
              options={additions}
              isMulti
              name="additions"
              className="basic-multi-select"
              classNamePrefix="select"
            />
            </div>
            </div>

            <button
              className="myBtn"
              onClick={calculateTotal}
              disabled={!series || !model || !size || !color}
            >
              Submit
            </button>
            <br />
            <br />

            <div id="totalDiv">
              <span>
                <h4>
                  Material Cost: ${materialTotal}
                  <Popup
                    trigger={
                      <button className="infoBtn">
                        {" "}
                        <FontAwesomeIcon icon={faCircleInfo} />
                      </button>
                    }
                    position="top right"
                    closeOnDocumentClick
                    keepTooltipInside=".transDiv"
                  >
                    <div>
                      <b>Itemized Cost Breakdown</b>
                      <MaterialInfo />
                    </div>
                  </Popup>
                </h4>
                <h4>
                  Install Cost: ${businessTotal}
                  <Popup
                    trigger={
                      <button className="infoBtn">
                        {" "}
                        <FontAwesomeIcon icon={faCircleInfo} />
                      </button>
                    }
                    position="top right"
                    closeOnDocumentClick
                    keepTooltipInside=".transDiv"
                  >
                    <div>
                      <b>Itemized Cost Breakdown</b>
                      <BusinessInfo />
                    </div>
                  </Popup>
                </h4>
                <h4>Total: ${fullTotal}</h4>
              </span>
            </div>
          </div>
        ) : (
          <div>
            <h2>401 Error: Unauthorized</h2>
            <p>
              This page is for admin use only. If you are an admin, please log
              in to use this page
            </p>
          </div>
        )}
      </div>
    </>
  );
}

export default QuotePage;
