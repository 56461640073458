import React, { Component } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./components/home/HomePage.js";
import ContactPage from "./components/contact/ContactPage.js";
// import GalleryPage from "./components/gallery/GalleryPage.js";
// import DoorsPage from "./components/services/DoorsPage.js";
// import OperatorsPage from "./components/services/OperatorsPage.js";
// import RepairsPage from "./components/services/RepairsPage.js";
import QuotePage from "./components/quotes/QuotePage.js";
import LoginPage from "./components/login/LoginPage.js";
import Footer from "./components/common/Footer.js";
import Navigation from "./components/common/Navigation.js";
import BurgerMenu from "./components/common/BurgerMenu.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import { Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";

const alertOps = {
  position: "middle",
  transitions: "scale",
};

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <AlertProvider template={AlertTemplate} {...alertOps}>
          <div className="main" id="main">
            <BurgerMenu pageWrapId={"page-wrap"} outerContainerId={"main"} />
            <div id="wrap-div">
              <Navigation />
              <div>
                <Routes>
                  <Route exact path="/" element={<HomePage />} />
                  <Route path="contact" element={<ContactPage />} />
                  <Route path="quote" element={<QuotePage />} />
                  <Route path="login" element={<LoginPage />} />
                  {/* <Route path="gallery" element={<GalleryPage />} />
                  <Route path="doors" element={<DoorsPage />} />
                  <Route path="operators" element={<OperatorsPage />} />
                  <Route path="repairs" element={<RepairsPage />} /> */}
                  <Route
                    path="*"
                    element={
                      <div className="pageDiv">
                        <h2>404 Error</h2>
                        <p>The page you're looking for doesn't exist.</p>
                      </div>
                    }
                  />
                </Routes>
              </div>
              <Footer />
            </div>
          </div>
        </AlertProvider>
      </BrowserRouter>
    );
  }
}

export default App;
