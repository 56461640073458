// Garage door colors
export const basicColors = [
  "white",
  "brown",
  "almond",
  "sandstone",
  "desert tan",
  "gray",
];
export const specialColors = ["walnut", "oak", "driftwood", "black"];
export const colors = basicColors.concat(specialColors);

// special colors are +20%
export const specialColorMod = 0.2;
export const specialColorWindowedMod = 0.25;
export const windowMod = 0.05;

// standard markup
export const markupMod = 0.2;

// standard tax
export const taxMod = 0.07;

// insulation per sqft
export const insulationPerSqft = 1.9;

// standard $ costs for amount of angle bracket used per door
export const angleCost = 60;

// set amount for installation per door
export const installCost = 500;

// reinforcement bracket cost
export const reinforcementBracketCost = 15.6;

// window prices
export const windowShortPrice = 31;
export const windowShortInsulated = 43;
export const windowRanchPrice = 72;

// insert prices
export const shortInsertPrice = 13;
export const longInsertPrice = 38;

// cost categories
export const MANUFACTURER = "manufacturer";
export const BUSINESS = "business";

// map a door color to trim color if it does not have its own trim
export const trimColorMap = {
  oak: "brown",
  walnut: "brown",
  driftwood: "brown",
  gray: "black",
};

// available trim pieces
export const trimData = [
  {
    length: 7,
    cost: 14.59,
    colors: ["almond", "brown", "desert tan", "sandstone", "white"],
  },
  {
    length: 8,
    cost: 16.67,
    colors: ["almond", "white"],
  },
  {
    length: 9,
    cost: 18.76,
    colors: ["almond", "brown", "sandstone", "white"],
  },
  {
    length: 10,
    cost: 20.84,
    colors: ["almond", "brown", "sandstone", "white"],
  },
  {
    length: 16,
    cost: 33.35,
    colors: ["almond", "brown", "desert tan", "sandstone", "white"],
  },
  {
    length: 16,
    cost: 36.47,
    colors: ["black"],
  },
  {
    length: 18,
    cost: 37.52,
    colors: ["almond", "brown", "desert tan", "sandstone", "white"],
  },
  {
    length: 18,
    cost: 41.06,
    colors: ["black"],
  },
];

// a lot of door data groupped together by series/model
export const doorData = [
  {
    name: "500",
    models: [
      {
        name: "510",
      },
      {
        name: "590",
      },
    ],
    sizes: {
      "8x7": 431.44,
      "8x8": 501.96,
      "9x7": 449.41,
      "9x8": 537.91,
      "10x7": 500.58,
      "10x8": 591.84,
      "12x7": 504.73,
      "12x8": 670.66,
      "16x7": 687.26,
      "16x8": 907.12,
      "18x7": 887.76,
    },
    requiresBracket: false,
  },
  {
    name: "400",
    models: [
      {
        name: "410",
      },
      {
        name: "420",
      },
      {
        name: "430",
      },
      {
        name: "450",
      },
      {
        name: "440",
      },
      {
        name: "480",
      },
    ],
    sizes: {
      "8x7": 465.26,
      "8x8": 538.73,
      "9x7": 482.53,
      "9x8": 574.72,
      "10x7": 537.28,
      "10x8": 632.34,
      "12x7": 556.0,
      "12x8": 728.85,
      "16x7": 734.62,
      "16x8": 975.17,
      "18x7": 953.57,
      "18x8": 1122.1,
    },
    requiresBracket: false,
  },
  {
    name: "3600",
    models: [
      {
        name: "3610",
      },
      {
        name: "3620",
      },
      {
        name: "3630",
      },
      {
        name: "3640",
      },
      {
        name: "3650",
      },
      {
        name: "3680",
      },
      {
        name: "3690",
      },
    ],
    sizes: {
      "8x66": 677.01,
      "8x7": 677.01,
      "8x8": 809.51,
      "9x66": 727.42,
      "9x7": 727.42,
      "9x8": 881.55,
      "10x66": 819.61,
      "10x7": 819.61,
      "10x8": 1012.61,
      "12x66": 1018.38,
      "12x7": 1018.38,
      "12x8": 1211.41,
      "16x7": 1212.83,
      "16x8": 1531.17,
      "18x66": 1460.6,
      "18x7": 1460.6,
      "18x8": 1783.25,
    },
    requiresBracket: true,
  },
];

export const additions = [
  {
    value: "Operators LDO33 7' Chain/Belt",
    label: "Operators LDO33 7' Chain/Belt",
    price: 159,
    category: "Operators",
  },
  {
    value: "Operators LDO33 8' Chain/Belt",
    label: "Operators LDO33 8' Chain/Belt",
    price: 193,
    category: "Operators",
  },
  {
    value: "Operators LDO33 10' Chain/Belt",
    label: "Operators LDO33 10' Chain/Belt",
    price: 219,
    category: "Operators",
  },
  {
    value: "Operators 8155W-8165W 7' Chain",
    label: "Operators 8155W-8165W 7' Chain",
    price: 259,
    category: "Operators",
  },
  {
    value: "Operators 8155W-8165W 8' Chain",
    label: "Operators 8155W-8165W 8' Chain",
    price: 287,
    category: "Operators",
  },
  {
    value: "Operators 8155W-8165W 10' Chain",
    label: "Operators 8155W-8165W 10' Chain",
    price: 318,
    category: "Operators",
  },
  {
    value: "Operators 8500W Jackshaft",
    label: "Operators 8500W Jackshaft",
    price: 575,
    category: "Operators",
  },
  {
    value: "Operator Install",
    label: "Operator Install",
    price: 100,
    category: "Operator Install",
  },
  {
    value: "Lift Style Low Headroom",
    label: "Lift Style Low Headroom",
    price: 150,
    category: "Lift Style",
  },
  {
    value: "Lift Style High Lift under 3'",
    label: "Lift Style High Lift under 3'",
    price: 325,
    category: "Lift Style",
  },
  {
    value: "Lift Style High Lift over 3'",
    label: "Lift Style High Lift over 3'",
    price: 500,
    category: "Lift Style",
  },
  {
    value: "Lift Style Vertical Lift under 100 sq ft",
    label: "Lift Style Vertical Lift under 100 sq ft",
    price: 350,
    category: "Lift Style",
  },
  {
    value: "Lift Style Vertical Lift between 100-200 sq ft",
    label: "Lift Style Vertical Lift between 100-200 sq ft",
    price: 700,
    category: "Lift Style",
  },
  {
    value: "Lift Style Vertical Lift over 200 sq ft",
    label: "Lift Style Vertical Lift over 200 sq ft",
    price: 1050,
    category: "Lift Style",
  },
  {
    value: "Back Jambs",
    label: "Back Jambs",
    price: 100,
    category: "Back Jambs",
  },
  {
    value: "Key Pad",
    label: "Key Pad",
    price: 40,
    category: "Key Pad",
  },
  {
    value: "Remotes",
    label: "Remotes",
    price: 20,
    category: "Remotes",
  },
  {
    value: "Deco Hardware",
    label: "Deco Hardware",
    price: 25,
    category: "Deco Hardware",
  },
  {
    value: "Cut Charge",
    label: "Cut Charge",
    price: 150,
    category: "Cut Charge",
  },
  {
    value: "Special Size Upcharge 9' NI",
    label: "Special Size Upcharge 9' NI",
    price: 330,
    category: "Special Size Upcharge",
  },
  {
    value: "Special Size Upcharge 10' NI",
    label: "Special Size Upcharge 10' NI",
    price: 400,
    category: "Special Size Upcharge",
  },
  {
    value: "Special Size Upcharge 12' NI",
    label: "Special Size Upcharge 12' NI",
    price: 600,
    category: "Special Size Upcharge",
  },
  {
    value: "Special Size Upcharge 14' NI",
    label: "Special Size Upcharge 14' NI",
    price: 950,
    category: "Special Size Upcharge",
  },
  {
    value: "Special Size Upcharge 9' INS",
    label: "Special Size Upcharge 9' INS",
    price: 400,
    category: "Special Size Upcharge",
  },
  {
    value: "Special Size Upcharge 10' INS",
    label: "Special Size Upcharge 10' INS",
    price: 500,
    category: "Special Size Upcharge",
  },
  {
    value: "Special Size Upcharge 12' INS",
    label: "Special Size Upcharge 12' INS",
    price: 750,
    category: "Special Size Upcharge",
  },
  {
    value: "Special Size Upcharge 14' INS",
    label: "Special Size Upcharge 14' INS",
    price: 1250,
    category: "Special Size Upcharge",
  },
];

// map any model to its series
export const modelToSeries = doorData.reduce(function (obj, series) {
  for (const model of series.models) obj[model.name] = series.name;
  return obj;
}, {});

// collection of all sizes
export const sizes = doorData.reduce(function (array, series) {
  array.push(...Object.keys(series.sizes));
  return array;
}, []);

// collection of all series
export const allSeries = doorData.reduce(function (array, series) {
  array.push(series.name);
  return array;
}, []);
