import * as constants from "./QuoteConstants.js";

/**
 * Raises error if color is not valid
 * @param {string} color Must be a valid color
 */
export function assertColor(color) {
  if (!constants.colors.includes(color))
    throw new Error(
      `${color} is not a valid color. Valid: ${constants.colors}`
    );
}

/**
 * Raises error if size is not valid
 * @param {string} size Must be a valid size
 */
export function assertSize(size) {
  if (!constants.sizes.includes(size))
    throw new Error(`${size} is not a valid size. Valid: ${constants.sizes}`);
}

/**
 * Raises error if series is not valid
 * @param {string} series Must be a valid size
 */
export function assertSeries(series) {
  if (!constants.allSeries.includes(series))
    throw new Error(
      `${series} is not a valid series. Valid: ${constants.allSeries}`
    );
}

/**
 * Returns all series options
 */
export function getSeries() {
  return constants.allSeries;
}

/**
 * Returns all model options for a given series
 * @param {string} series Selected series
 */
export function getModels(series) {
  assertSeries(series);
  let models = [];
  for (const s of constants.doorData) {
    if (s.name !== series) continue;
    for (const m of s.models) models.push(m.name);
    break;
  }
  return models;
}

/**
 * Returns all size options for a given series / model
 * @param {string} series Selected series
 */
export function getSizes(series) {
  assertSeries(series);
  let sizes = [];
  for (const s of constants.doorData) {
    if (s.name !== series) continue;
    sizes.push(...Object.keys(s.sizes));
    break;
  }
  return sizes;
}

/**
 * Splits text size into numeric dimensions as an object
 * @param {string} size Width x Height in "WxH" format
 */
export function splitSize(size) {
  let parts = size.split("x");
  parts = { w: Number(parts[0]), h: Number(parts[1]) };
  if (parts.h === 66) parts.h = 6.5;
  return parts;
}

export function hasStrut(series, size) {
  if (splitSize(size).w >= 16) return false;
  return true;
}

/**
 * Returns $ cost for the strut required for the door
 *
 * These are the 2 1/4" Strut costs on the price list
 *
 * @param {string} size Must be a valid size for a door
 */
export function quoteStrut(size) {
  let width = splitSize(size).w;
  if (width <= 8) return 15;
  if (width <= 9) return 17;
  if (width < 16) return 30;
  // 16' or greater come with required struts
  return 0;
}

/**
 * Returns true if this series requires an operator bracket
 * @param {boolean} series
 */
export function hasBracket(series) {
  assertSeries(series);
  for (const s of constants.doorData) {
    if (series === s.name) {
      return s.requiresBracket === true;
    }
  }
  return false;
}

/**
 * Get the trim pieces used for the door
 * @param {string} size Must be a valid size for a garage door
 * @param {string} color Must be a valid color for the size
 */
export function getTrims(size, color) {
  assertColor(color);
  size = splitSize(size);

  // convert special colors to trim color
  if (color in constants.trimColorMap) {
    color = constants.trimColorMap[color];
  }

  // build list of trims in this color
  let available = [];
  for (const trim of constants.trimData) {
    if (trim.colors.includes(color)) available.push(trim);
  }
  // sort by shortest size first
  available.sort((a, b) => a.length - b.length);

  let widthTrim = null;
  let soloHeightTrim = null;
  let comboHeightTrim = null;
  for (const trim of available) {
    // width always goes for 1 piece to cover whole distance
    if (!widthTrim && trim.length >= size.w) {
      widthTrim = trim;
    }
    if (!soloHeightTrim && trim.length >= size.h) {
      soloHeightTrim = trim;
    }
    if (!comboHeightTrim && trim.length >= size.h * 2) {
      comboHeightTrim = trim;
    }
  }

  // 20' spans are not in scope for this application
  if (!widthTrim) throw new Error(`Available trims do not cover entire span`);

  // use 1 trim long enough for both sides and cheaper
  // otherwise just buy two of the small trims
  if (comboHeightTrim && comboHeightTrim.cost < soloHeightTrim.cost * 2)
    return [widthTrim, comboHeightTrim];
  else return [widthTrim, soloHeightTrim, soloHeightTrim];
}

/**
 * Get $ cost of trim for a particular door based on size & color
 * @param {string} size Must be a valid size for a garage door
 * @param {string} color Must be a valid color for the size
 */
export function quoteTrims(size, color) {
  let trims = getTrims(size, color);
  let cost = 0;
  for (const trim of trims) cost += trim.cost;
  return cost;
}

export function isRanchStyle(model) {
  return !model.endsWith("10");
}

/**
 * Get number of windows for a given door
 * @param {string} model model of door
 * @param {string} size size of door
 * @param {boolean} hasInsulation whether this door is insulated
 */
export function getWindows(model, size, hasInsulation) {
  let numWindows = 0;
  size = splitSize(size);
  let series = constants.modelToSeries[model];
  if (isRanchStyle(model)) {
    // 1 window/4ft
    numWindows = Math.floor(size.w / 4);
    return [
      {
        name: "long window",
        costEach: constants.windowRanchPrice,
        number: numWindows,
        insertCost: constants.longInsertPrice,
      },
    ];
  } else {
    // 1 window/2ft
    numWindows = Math.floor(size.w / 2);
    if (series.startsWith("36") || hasInsulation) {
      return [
        {
          name: "short window - insulated",
          costEach: constants.windowShortInsulated,
          number: numWindows,
          insertCost: constants.shortInsertPrice,
        },
      ];
    } else {
      return [
        {
          name: "short window",
          costEach: constants.windowShortPrice,
          number: numWindows,
          insertCost: constants.shortInsertPrice,
        },
      ];
    }
  }
}

/**
 * Get the amount per square foot insulation is for a door
 * @param {string} model must be a valid model
 * @param {string} size must be a valid size
 */
export function insulationPricePerSqFt(model, size) {
  return constants.insulationPerSqft;
}

/**
 * Get the $ amount it takes to insulate a door
 * @param {string} model must be a valid model
 * @param {string} size must be a valid size
 */
export function quoteInsulation(model, size) {
  // find series from model
  let perSqFt = insulationPricePerSqFt(model, size);
  size = splitSize(size);
  let area = size.w * size.h;
  return area * perSqFt;
}

export function sumItems(items, type) {
  return items.reduce((s, item) => {
    if (type && item.type !== type) return s;
    return s + Number(item.cost);
  }, 0);
}

export function addItem(items, name, cost, type, info) {
  // cost in $ - round to 2 decimals
  items.push({ itemName: name, cost: cost.toFixed(2), type: type, info: info });
}

export function doorBasePrice(series, size) {
  for (const s of constants.doorData) {
    if (s.name === series) {
      if (size in s.sizes) return s.sizes[size];
      else
        throw new Error(
          `${size} is not a valid door size in the ${s.name} series`
        );
    }
  }
}

export function itemizeBaseDoor(props, items) {
  if (!items) {
    items = [];
  }
  // series and model have no value without size so are grouped together
  addItem(
    items,
    `${props.series}-${props.model}-${props.size}`,
    doorBasePrice(props.series, props.size),
    constants.MANUFACTURER,
    "base price without features/add-ons"
  );
}

export function itemizeInsulation(props, items) {
  if (!items) {
    items = [];
  }
  // add insulation cost, if any
  if (props.hasInsulation) {
    addItem(
      items,
      "insulation",
      quoteInsulation(props.model, props.size),
      constants.MANUFACTURER,
      `cost per sqft: $${insulationPricePerSqFt(props.model, props.size)}`
    );
  }
}

export function itemizeWindows(props, items) {
  if (!items) {
    items = [];
  }
  // add windows cost, if any
  if (props.hasWindows) {
    let windows = getWindows(props.model, props.size, props.hasInsulation);
    for (const w of windows) {
      addItem(
        items,
        `${w.number} ${w.name}`,
        w.number * w.costEach,
        constants.MANUFACTURER,
        "tied to series, model & size"
      );
    }
  }
}

export function itemizeSpecialOrderMarkup(props, items) {
  if (!items) {
    items = [];
  }
  // run the calculations for special color markup
  let priceOfDoorSoFar = sumItems(items);
  if (constants.specialColors.includes(props.color)) {
    if (props.hasWindows) {
      addItem(
        items,
        `special color + windows ${Math.round(
          constants.specialColorWindowedMod * 100
        )}%`,
        constants.specialColorWindowedMod * priceOfDoorSoFar,
        constants.MANUFACTURER,
        `plus ${Math.round(constants.specialColorWindowedMod * 100)}%`
      );
    } else {
      addItem(
        items,
        `special color ${Math.round(constants.specialColorMod * 100)}%`,
        constants.specialColorMod * priceOfDoorSoFar,
        constants.MANUFACTURER,
        `plus ${Math.round(constants.specialColorMod * 100)}%`
      );
    }
  } else if (props.hasWindows) {
    addItem(
      items,
      `windows ${Math.round(constants.windowMod * 100)}%`,
      constants.windowMod * priceOfDoorSoFar,
      constants.MANUFACTURER,
      `plus ${Math.round(constants.windowMod * 100)}%`
    );
  }
}

export function itemizeWindowInserts(props, items) {
  if (!items) {
    items = [];
  }
  // add inserts, if any. Only present if windows
  if (props.hasWindows && props.hasInserts) {
    for (const w of getWindows(props.model, props.size)) {
      addItem(
        items,
        `${w.number} insert for ${w.name}`,
        w.insertCost * w.number,
        constants.MANUFACTURER,
        "tied to # and type of window"
      );
    }
  }
}

export function itemizeTrim(props, items) {
  if (!items) {
    items = [];
  }
  addItem(
    items,
    "door trim",
    quoteTrims(props.size, props.color),
    constants.MANUFACTURER,
    "based on size & color of door"
  );
}

export function itemizeAngle(props, items) {
  if (!items) {
    items = [];
  }
  addItem(
    items,
    "angle bracket",
    constants.angleCost,
    constants.MANUFACTURER,
    `always $${constants.angleCost} per door`
  );
}

export function itemizeOperatorReinforcement(props, items) {
  if (!items) {
    items = [];
  }
  if (hasBracket(props.series)) {
    addItem(
      items,
      "operator reinforcement bracket",
      constants.reinforcementBracketCost,
      constants.MANUFACTURER,
      "required for 3600 series doors"
    );
  }
}

export function itemizeStrut(props, items) {
  if (!items) {
    items = [];
  }
  if (hasStrut(props.series, props.size)) {
    addItem(
      items,
      "strut",
      quoteStrut(props.size),
      constants.MANUFACTURER,
      "required for <16' wide doors"
    );
  }
}

export function itemizeNumberOfDoors(props, items) {
  if (!items) {
    items = [];
  }
  let additionalDoors = props.quantity - 1;
  if (additionalDoors > 0) {
    let singleDoorCost = sumItems(items);
    addItem(
      items,
      `${additionalDoors} additional doors`,
      singleDoorCost * additionalDoors,
      constants.MANUFACTURER,
      "the price of the additional doors"
    );
  }
}

export function itemizeTax(props, items) {
  if (!items) {
    items = [];
  }
  addItem(
    items,
    "tax",
    sumItems(items) * constants.taxMod,
    constants.MANUFACTURER,
    `${Math.round(
      constants.taxMod * 100
    )}% sales tax of items purchased from supplier`
  );
}

export function itemizeMarkup(props, items) {
  if (!items) {
    items = [];
  }
  addItem(
    items,
    "business markup",
    sumItems(items) * constants.markupMod,
    constants.BUSINESS,
    `plus ${Math.round(constants.markupMod * 100)}%`
  );
}

export function itemizeInstall(props, items) {
  if (!items) {
    items = [];
  }
  addItem(
    items,
    `install ${props.quantity} door `,
    constants.installCost * props.quantity,
    constants.BUSINESS,
    `$${constants.installCost} per door`
  );
}

export function itemizeDiscount(props, items) {
  if (!items) {
    items = [];
  }
  if (props.discount && props.discount !== 100) {
    let discountMod = (props.discount - 100) / 100;
    let discountDesc = discountMod < 0 ? "discount" : "markup";
    addItem(
      items,
      `${props.discount - 100}% additional ${discountDesc}`,
      sumItems(items, constants.BUSINESS) * discountMod,
      constants.BUSINESS,
      `${props.discount - 100}% adjustment of amount paid to installer`
    );
  }
}

export function itemizeAdditions(props, items) {
  if (!items) {
    items = [];
  }
  if (!props.additionalItems || props.additionalItems.length == 0) {
    return;
  }
  let summedAdditions = props.additionalItems.reduce((s, item) => {
    return s + Number(item.price);
  }, 0);
  addItem(
    items,
    "additions",
    summedAdditions,
    constants.MANUFACTURER,
    "Retail Price of Additional Items"
  );
}

/**
 * Get itemized entries for a quote on a garage door
 *
 * Expected props attributes
 * props.series          : string
 * props.model           : string
 * props.size            : string
 * props.hasInsulation   : boolean
 * props.hasWindows      : boolean
 * props.hasInserts      : boolean
 * props.color           : string
 * props.quantity        : integer
 * props.additionalItems : array - [{"name": "foo", "price": 42.00, "category": "bar"}]
 *
 * @param {Object} props
 */
export function itemizeDoorQuote(props) {
  // itemized entries in the quote
  let items = [];
  itemizeBaseDoor(props, items);
  itemizeInsulation(props, items);
  itemizeWindows(props, items);
  itemizeWindowInserts(props, items);
  // position of this is important - based on sum of items
  itemizeSpecialOrderMarkup(props, items);
  itemizeTrim(props, items);
  itemizeAngle(props, items);
  itemizeOperatorReinforcement(props, items);
  itemizeStrut(props, items);
  // position of this is important - based on sum of items
  itemizeNumberOfDoors(props, items);
  itemizeAdditions(props, items);
  // position of this is important - based on sum of items
  itemizeTax(props, items);
  // position of this is important - based on sum of items
  itemizeMarkup(props, items);
  itemizeInstall(props, items);
  // position of this is important - based on sum of items
  itemizeDiscount(props, items);
  console.log(items);
  return items;
}
