import React, { useState } from "react";
import trnsp from "../../assets/trnspLogo.png";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import RequestBtn from "./RequestBtn.js";

function Navigation() {
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.isLoggedIn);

  return (
    <>
      <Navbar className="mobileNav">
        <Navbar.Brand href="/" style={{ padding: "0" }}>
          <img className="logoMobile" src={trnsp} alt="Hollywood_Doors_Logo" />
        </Navbar.Brand>
      </Navbar>

      <Navbar className="mainNavbar">
        <Navbar.Brand href="/" style={{ padding: "0" }}>
          <img className="logoLg" src={trnsp} alt="Hollywood_Doors_Logo" />
        </Navbar.Brand>
        <LinkContainer className="navContainer" to="/">
          <Nav.Link>Home</Nav.Link>
        </LinkContainer>
        <NavDropdown
          className="navContainer"
          title="Garage Services"
          style={{ display: "none" }}
        >
          <LinkContainer className="navDrop" to="/doors">
            <Nav.Link>New Garage Doors</Nav.Link>
          </LinkContainer>
          <LinkContainer className="navDrop" to="/operators">
            <Nav.Link>New Operators</Nav.Link>
          </LinkContainer>
          <LinkContainer className="navDrop" to="/repairs">
            <Nav.Link>Repairs</Nav.Link>
          </LinkContainer>
        </NavDropdown>
        <Nav.Link
          href="https://doorlinkmfg.com/design-shop/"
          className="navContainer"
          target="_blank"
          rel="noopener noreferrer"
        >
          Design Your Door
        </Nav.Link>
        <LinkContainer
          className="navContainer"
          to="/gallery"
          style={{ display: "none" }}
        >
          <Nav.Link>Photo Gallery</Nav.Link>
        </LinkContainer>
        <LinkContainer className="navContainer" to="/contact">
          <Nav.Link>Contact Us</Nav.Link>
        </LinkContainer>
        {isLoggedIn ? (
          <LinkContainer className="navContainer" to="/quote">
            <Nav.Link>Quotes &#40;Admin&#41;</Nav.Link>
          </LinkContainer>
        ) : null}
        <div style={{marginRight: "100px"}}>
          <RequestBtn>Request Service</RequestBtn>
        </div>
      </Navbar>
    </>
  );
}

export default Navigation;
