import React from "react";
import { slide as Menu } from "react-burger-menu";
import "./burgerMenu.css";
import RequestBtn from "./RequestBtn.js";

function BurgerMenu() {
  const [menuOpen, setMenuOpen] = React.useState(false);

  const handleStateChange = (state) => {
    setMenuOpen(state.isOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <Menu
      right
      isOpen={menuOpen}
      disableAutoFocus
      onStateChange={(state) => handleStateChange(state)}
    >
      <a
        className="menu-item"
        style={{ borderTop: "1px solid #bdc3c7" }}
        href="/"
      >
        Home
      </a>
      <a className="menu-item" href="/doors" style={{ display: "none" }}>
        New Garage Doors
      </a>
      <a className="menu-item" href="/operators" style={{ display: "none" }}>
        New Operators
      </a>
      <a className="menu-item" href="/repairs" style={{ display: "none" }}>
        Repairs
      </a>
      <a
        className="menu-item"
        href="https://doorlinkmfg.com/design-shop/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Design Your Door
      </a>
      <a className="menu-item" href="/gallery" style={{ display: "none" }}>
        Photo Gallery
      </a>
      <a className="menu-item" href="/contact">
        Contact Us
      </a>
      {localStorage.isLoggedIn ? (
        <a className="menu-item" href="/quote">
          Quotes &#40;Admin&#41;
        </a>
      ) : null}

      <div
        style={{ borderBottom: "none" }}
        onClick={() => {
          closeMenu();
        }}
      >
        <RequestBtn>Request Service</RequestBtn>
      </div>
      <a
        className="menu-item"
        style={{ position: "absolute", bottom: "0", right: "25px" }}
        href="/login"
      >
        Admin Login
      </a>
    </Menu>
  );
}

export default BurgerMenu;
