import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-modal";
import RequestModal from "../request/RequestModal.js";

Modal.setAppElement("#root");

function RequestBtn(props) {
  const [showModal, setShowModal] = useState(false);

  const customStyles = {
    overlay: { zIndex: 1000 },
  };

  function openModal() {
    setShowModal(true);
  }

  function closeModal() {
    setShowModal(false);
  }

  return (
    <>
      <Button
        type="button"
        variant="custom"
        className="myBtn"
        size="lg"
        onClick={openModal}
      >
        {props.children}
      </Button>
      <Modal
        isOpen={showModal}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        contentLabel="Request Modal"
        className="Modal"
        style={customStyles}
      >
        <RequestModal TypeInit={props.TypeInit} closeModal={closeModal} />
      </Modal>
    </>
  );
}

export default RequestBtn;
