import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";

const Footer = () => (
  <div className="contactFooter">
    <footer style={{ fontFamily: "Times New Roman" }}>
      @ Holly Wood Doors 2024 &emsp; | &emsp;
      <FontAwesomeIcon icon={faPhone} />{" "}
      <a href="tel:+14236457147" style={{ color: "white" }}>
        (423) 645-7147
      </a>
      &emsp; | &emsp;
      <a
        href="https://www.facebook.com/hollywooddoors"
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "white" }}
      >
        <FontAwesomeIcon icon={faFacebookF} />
      </a>
      &emsp; | &emsp;
      <a href="/login" style={{ color: "white" }}>
        <i>Admin Login</i>
      </a>
    </footer>
  </div>
);

export default Footer;
