import React from "react";
import garageDoor from "../../assets/bestDoor.jpg";
import operator from "../../assets/liftMaster.jpg";
import repair from "../../assets/repair.jpg";
import dlCarriage from "../../assets/dlCarriage.jpg";
import dlFlush from "../../assets/dlFlush.jpg";
import dlRaised from "../../assets/dlRaised.jpg";
import lmRed from "../../assets/liftMasterRed.jpg";
import lmSmart from "../../assets/liftMasterSmart.png";
import { Card, Carousel, Nav } from "react-bootstrap";
import RequestBtn from "../common/RequestBtn.js";

const ServicesSlides = () => (
  <>
    <Carousel>
      <Carousel.Item>
        <img
          className="w-100 slideshow"
          src={garageDoor}
          alt="New Garage Door"
        />
        <Carousel.Caption>
          <h1>New Garage Doors</h1>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="w-100 slideshow" src={operator} alt="New Operators" />
        <Carousel.Caption>
          <h1>New Operators</h1>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="w-100 slideshow"
          src={repair}
          alt="Repairs and Replacements"
        />
        <Carousel.Caption>
          <h1>Repairs and Replacements</h1>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  </>
);

const About = () => (
  <>
    <div className="aboutWrapper">
      <div className="aboutDiv">
        <Card className="mainCards" style={{ flex: "3" }}>
          <Card.Body>
            <Card.Title className="cardTitle">
              <b>Local Service You Can Trust</b>
            </Card.Title>
            <Card.Text className="mainBody">
              Looking for a new garage door to give your home a facelift?
              <br />
              Want a new high-tech operator?
              <br />
              Or does your door simply need a few repairs?
              <br /> <br />
              Holly Wood Doors can help! Veteran owned and operated, with over
              20 years of experience, our technicians have installed and
              repaired doors all across the Greater Chattanooga area. We pride
              ourselves on providing only the highest quality service at the
              best price for you. We're only trying to make a living, not a
              killing.
              <br />
              <br />
              Fill out our online request form or give us a call to schedule a
              quote today!
            </Card.Text>
          </Card.Body>
        </Card>
        <SideInfo style={{ flex: "1" }} />
      </div>
    </div>
  </>
);

const SideInfo = () => (
  <>
    <Card className="sideInfo">
      <Card.Body>
        <Card.Title style={{ fontSize: "26px" }}>
          Primary Service Areas
        </Card.Title>
        <Card.Text>
          <ul>
            <li>Hamilton County</li>
            <li>Catoosa County</li>
            <li>Walker County</li>
            <li>Bradley County</li>
            <li>...and More!</li>
          </ul>
          <br />
        </Card.Text>
      </Card.Body>
    </Card>
  </>
);

const Doors = () => (
  <>
    <div
      style={{
        backgroundImage:
          "linear-gradient(0deg, rgba(84,105,119,1) 0%, rgba(180,210,230,1) 100%)",
        paddingBottom: "30px",
      }}
    >
      <h2 className="cardTitle" id="productsTitle">
        <b>Our Garage Doors: DoorLink Manufacturing</b>
      </h2>
      <div className="productsDiv">
        <Card className="productCards">
          <Card.Img
            variant="top"
            src={dlCarriage}
            alt="Carriage-style Garage"
          />
          <Card.Body>
            <Card.Text className="mainBody">
              We recommend DoorLink to all of our customers not only for their
              high quality materials, but also for their wide range of
              customization options.
              <br />
              They've been in business for over 100 years and are trusted across
              the nation.
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className="productCards">
          <Card.Img variant="top" src={dlFlush} alt="Modern-style Garage" />
          <Card.Body>
            <Card.Text className="mainBody">
              DoorLink produces highly secure 24 gauge galvanized steel doors
              that are built to last. With the addition of double hemmed inner
              return rails and rolled panel edges, these doors provide superior
              strength and durability.
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className="productCards">
          <Card.Img variant="top" src={dlRaised} alt="Raised Panel Garage" />
          <Card.Body>
            <Card.Text className="mainBody">
              DoorLink offers near limitless style options and quality-of-life
              features. Whether you're interested in a sleek modern door, a cozy
              farmhouse door, or anything in between, you can find the look
              you're going for with DoorLink.
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
      <div className="productBtnsDiv">
        <Nav.Link
          href="https://doorlinkmfg.com/design-shop/"
          target="_blank"
          className="myBtn linkBtn"
        >
          Design Your Own!
        </Nav.Link>
        <RequestBtn>Schedule a Quote</RequestBtn>
      </div>
    </div>
  </>
);

const Operators = () => (
  <>
    <div
      style={{
        backgroundImage:
          "linear-gradient(0deg, rgb(84, 145, 185, 1) 0%, rgb(155, 201, 231, 1) 100%)",
        paddingBottom: "30px",
      }}
    >
      <h2 className="cardTitle" id="productsTitle">
        <b>Our Operators: LiftMaster</b>
      </h2>
      <div className="productsDiv">
        <Card className="productCards">
          <Card.Img
            variant="top"
            src={lmRed}
            alt="Red LiftMaster Operator"
            className="operatorPic"
          />
          <Card.Body>
            <Card.Text className="mainBody">
              LiftMaster is one of the top garage door operators in the nation.
              They have the option between an ultra-strong, virtually silent
              Belt Drive, or a powerful Chain Drive for heavier, frequently used
              doors. Either way, their operators are reliable and long-lasting
              for your home.
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className="productCards">
          <Card.Img
            variant="top"
            src={lmSmart}
            alt="Smart LiftMaster Operator"
            className="operatorPic"
          />
          <Card.Body>
            <Card.Text className="mainBody">
              LiftMaster can also be integrated into your smarthome! Their Next
              Gen operators have several Smart options that link up to your
              phone and smart devices. Different models can also include a
              built-in camera, LED lighting, and battery backup. <br />
              <a
                href="https://www.liftmaster.com/buyers-guide/garage-door-openers"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "white" }}
              >
                Learn more here!
              </a>
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
      <div className="productBtnsDiv">
        <RequestBtn TypeInit={"New Operator"}>Schedule a Quote</RequestBtn>
      </div>
    </div>
  </>
);

const HomePage = () => (
  <>
    <div className="pageDiv">
      <ServicesSlides />
      <About />
      <Doors />
      <Operators />
    </div>
  </>
);

export default HomePage;
